import React, { useState } from "react"
import { Container, Row, Table } from "reactstrap"
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import Chip from "@mui/material/Chip"
import Button from "@mui/material/Button"
import Skeleton from "@mui/material/Skeleton"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import Pagination from "@mui/material/Pagination"
import BasicCalendar from "../../../components/BasicCalendar"
import Modal from "../../../components/Modal"
import "./styles.css"

const JobListTemplate = ({
  jobs,
  isLoading,
  onInputSearchChange,
  onSearch,
  handleOnClickViewJob,
  // handleOnClickDeleteJob,
  // handleUpdateJobList,
  totalPages,
  currentPage,
  onPageChange,
  campanas,
  status,
  handleOnChangeCampana,
  handleOnChangeStatus,
  selectedCampana,
  selectedStatus,
  handleClickFilter,
  setCalendarValueStart,
  calendarValueStart,
  setCalendarValueEnd,
  calendarValueEnd,
  handleCLickCleanFilter,
  rutValue,
  open = false,
  showModal = () => {},
}) => {
  const statusMapping = {
    PENDIENTE: { label: "Pendiente", color: "info" },
    APROBADA: { label: "Aprobada", color: "success" },
    RECHAZADA: { label: "Rechazada", color: "error" },
    processing: { label: "En proceso", color: "warning" },
  }

  // Si el estado no está en el mapeo, se usa un valor por defecto
  const defaultStatus = { label: "En proceso", color: "warning" } 

  const isButtonDisabled = status => {
    switch (status) {
      case "completed":
      case "locked":
      case "processing":
      case "pendiente":
        return true
      default:
        return false
    }
  }

  const buttonColorMapping = {
    locked: "error",
    default: "primary", // Valor por defecto
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Modal open={open} setOpen={showModal} />
        <Breadcrumb title="Lista de trabajos" />
        <div className="row mb-4 justify-content-space-between">
          <div className="col-2 d-flex flex-row">
            <TextField
              id="outlined-basic"
              label="Fitlrar por Rut"
              variant="outlined"
              onChange={onInputSearchChange}
              size="small"
              value={rutValue}
            />
            <Button variant="contained" onClick={onSearch}>
              <SearchIcon />
            </Button>
          </div>
          <div className="col-2">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" size="small">
                Filtrar por Campaña
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCampana}
                label="Filtrar por Campaña"
                onChange={e => handleOnChangeCampana(e)}
                size="small"
              >
                {campanas?.map((campana, index) => (
                  <MenuItem key={index} value={campana}>
                    {campana}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-2">
            {" "}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" size="small">
                Filtrar por Estado
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedStatus}
                label="Filtrar por Campaña"
                onChange={e => handleOnChangeStatus(e)}
                size="small"
              >
                {status?.map((status, index) => (
                  <MenuItem key={index} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-2">
            <BasicCalendar
              label="Fecha Inicio"
              setCalendarValue={setCalendarValueStart}
              calendarValue={calendarValueStart}
            />
          </div>
          <div className="col-2">
            <BasicCalendar
              label="Fecha Fin"
              setCalendarValue={setCalendarValueEnd}
              calendarValue={calendarValueEnd}
            />
          </div>
          <div className="col-2 d-flex flex-row gap-2">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleClickFilter()}
            >
              Filtrar
            </Button>
            <Button
              variant="contained"
              color="info"
              size="small"
              onClick={() => handleCLickCleanFilter()}
            >
              Reestablecer
            </Button>
          </div>
        </div>
        <Row>
          <div className="col-lg-12">
            <div className="card p-3">
              <div className="table-wrapper">
                <Table responsive>
                  <thead className="sticky-header text-center">
                    <tr>
                      <th style={{ width: "20%", textAlign: "left" }}>
                        Rut Cliente
                      </th>
                      <th style={{ width: "20%", textAlign: "left" }}>
                        Seguro
                      </th>
                      <th style={{ width: "20%", textAlign: "left" }}>
                        Campana
                      </th>
                      <th>Estado</th>
                      <th>Fecha Venta</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading
                      ? Array.from(new Array(5)).map((_, index) => (
                          <tr key={index}>
                            <td>
                              <Skeleton variant="text" width="100%" />
                            </td>
                            <td>
                              <Skeleton variant="text" width="100%" />
                            </td>
                            <td>
                              <Skeleton variant="text" width="100%" />
                            </td>
                            <td>
                              <Skeleton variant="text" width="80%" />
                              <Skeleton
                                variant="text"
                                width="80%"
                                style={{ marginLeft: 8 }}
                              />
                            </td>
                          </tr>
                        ))
                      : jobs?.map((job, index) => (
                          <tr key={index}>
                            <td>{job.rut_cliente}</td>
                            <td>{job.seguro}</td>
                            <td>{job.campana}</td>
                            <td className="text-center">
                              <Chip
                                label={
                                  statusMapping[job?.status]?.label ||
                                  defaultStatus.label
                                }
                                color={
                                  statusMapping[job?.status]?.color ||
                                  defaultStatus.color
                                }
                                variant="filled"
                              />
                            </td>
                            <td className="text-center">{job?.fecha_venta}</td>
                            <td className="text-center">
                              <Button
                                size="small"
                                color={
                                  buttonColorMapping[job?.job_status] ||
                                  buttonColorMapping.default
                                }
                                className="mx-2"
                                onClick={() => handleOnClickViewJob(job.job_id)}
                                disabled={isButtonDisabled(job?.status)}
                                variant="outlined"
                              >
                                ver
                              </Button>

                              {/* <Button
                                size="small"
                                color="error"
                                className="mx-2"
                                onClick={() =>
                                  handleOnClickDeleteJob(job.job_id)
                                }
                                variant="outlined"
                              >
                                Eliminar
                              </Button> */}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </div>
              <div className="row mt-4">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, value) => onPageChange(value)}
                  color="primary"
                  className="pagination"
                />
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default JobListTemplate
