import { all, fork } from "redux-saga/effects"

// Sagas públicas
import AccountSaga from "./auth/register/saga"
// import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"

// Jobs module
import fetchJobById from "./jobList/getJobById/saga"
import fetchAllJobsSaga from "./jobList/getAllJobs/saga"
import deleteJobSaga from "./jobList/deleteJobById/saga"

// Transcription
import { fetchTranscriptionSaga } from "./transcription/saga"

// Transcription Evaluation
import { updateTranscriptionEvaluationSaga } from "./transcription/saga"

// Evaluation Summary
import { fetchSummaryResultSaga } from "./transcription/saga"

// Upload file
import uploadSaga from "./upload/saga"

// Server Events
import serverEventsSaga from "./serverEvents/saga"

// Users
import createUserSaga from "./users/saga"
import { getAllUsersSaga } from "./users/saga"
import { getUserByIdSaga } from "./users/saga"
import { deleteUserSaga } from "./users/saga"

// Login
import { LoginUserSaga } from "./auth/login/saga"
import { LogoutUserSaga } from "./auth/login/saga"

// LEMUR Analisys
import fetchLemurAnalisysSaga from "./lemurAnalisys/saga"

//File Info
import { fetchFilesInfoSaga } from "./filesInfo/saga"
// import searchFilesInfoSaga from "./filesInfo/saga"

// Views
import { FetchJobListViewSaga } from "./views/saga"

// Observations
import createObservationSaga from "./observations/saga"
import { getObservationsSaga } from "./observations/saga"

// Lease Jobs
import { leaseJobsSaga } from "./leaseJobs/saga"

// Logger

import { loggerSaga } from "./logger/saga"

export default function* rootSaga() {
  yield all([
    fork(AccountSaga),
    // fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(fetchAllJobsSaga),
    fork(fetchJobById),
    fork(deleteJobSaga),
    fork(fetchTranscriptionSaga),
    fork(updateTranscriptionEvaluationSaga),
    fork(uploadSaga),
    fork(serverEventsSaga),
    fork(fetchLemurAnalisysSaga),
    fork(createUserSaga),
    fork(getAllUsersSaga),
    fork(deleteUserSaga),
    fork(LoginUserSaga),
    fork(LogoutUserSaga),
    fork(getUserByIdSaga),
    fork(fetchFilesInfoSaga),
    fork(FetchJobListViewSaga),
    fork(fetchSummaryResultSaga),
    fork(createObservationSaga),
    fork(getObservationsSaga),
    fork(leaseJobsSaga),
    fork(loggerSaga),
  ])
}
