import {
  FETCH_LOGS,
  FETCH_LOGS_SUCCESS,
  FETCH_LOGS_ERROR,
  FETCH_LOGS_BY_DATE,
  FETCH_LOGS_BY_DATE_SUCCESS,
  FETCH_LOGS_BY_DATE_ERROR,
} from "./actionTypes"

// Acción para disparar el fetch de logs
export const fetchLogs = data => ({
  type: FETCH_LOGS,
  payload: data, // Se pasa el payload con los datos
})

// Acción para el éxito al obtener los logs
export const fetchLogsSuccess = data => ({
  type: FETCH_LOGS_SUCCESS,
  payload: data,
})

// Acción para manejar el error
export const fetchLogsError = error => ({
  type: FETCH_LOGS_ERROR,
  payload: error,
})

export const fetchLogsByDate = data => ({
  type: FETCH_LOGS_BY_DATE,
  payload: data,
})

export const fetchLogsByDateSuccess = data => ({
  type: FETCH_LOGS_BY_DATE_SUCCESS,
  payload: data,
})

export const fetchLogsByDateError = error => ({
  type: FETCH_LOGS_BY_DATE_ERROR,
  payload: error,
})
