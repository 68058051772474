import { call, put, takeEvery } from "redux-saga/effects"
import { FETCH_LOGS, FETCH_LOGS_BY_DATE } from "./actionTypes"
import api from "../api"

import {
  fetchLogsSuccess,
  fetchLogsError,
  fetchLogsByDateSuccess,
  fetchLogsByDateError,
} from "./actions"

// Usamos una URL base para la API
const BASE_URL = process.env.REACT_APP_NEST_API

export function* fetchLogs(action) {
  try {
    const response = yield call(
      api.post,
      BASE_URL,
      "/logs/crear/",
      action.payload
    )
    yield put(fetchLogsSuccess({ data: response.data }))
  } catch (error) {
    yield put(fetchLogsError(error.toString()))
  }
}

export function* fetchLogsByDate(action) {
  try {
    const response = yield call(
      api.get,
      BASE_URL,
      `/logs/?fechaInicio=${action.payload.startDate}&fechaFin=${
        action.payload.endDate ? action.payload.endDate : ""
      }&tipo=${action.payload.type ? action.payload.type : ""}`
    )
    yield put(fetchLogsByDateSuccess({ data: response.data }))
  } catch (error) {
    yield put(fetchLogsByDateError(error.toString()))
  }
}

export function* loggerSaga() {
  yield takeEvery(FETCH_LOGS, fetchLogs)
  yield takeEvery(FETCH_LOGS_BY_DATE, fetchLogsByDate)
}
