import {
  FETCH_LOGS,
  FETCH_LOGS_SUCCESS,
  FETCH_LOGS_ERROR,
  FETCH_LOGS_BY_DATE,
  FETCH_LOGS_BY_DATE_SUCCESS,
  FETCH_LOGS_BY_DATE_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  data: {},
  error: null,
  report: [],
}

const loggerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOGS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      }
    case FETCH_LOGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case FETCH_LOGS_BY_DATE:
      return {
        ...state,
        loading: true,
      }
    case FETCH_LOGS_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        report: action.payload,
        error: null,
      }
    case FETCH_LOGS_BY_DATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default loggerReducer
