import React from "react"
import { Navigate } from "react-router-dom"
import UserProfile from "../pages/Authentication/user-profile"
import Administration from "../pages/Administration"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Dashboard from "../pages/Dashboard/index"
import JobList from "../pages/JobList"
import JobResults from "../pages/JobResults"
import Upload from "../pages/Upload"
import Reports from "../pages/Reports"

const authProtectedRoutes = [
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/profile", element: <UserProfile /> },
  { path: "/administration", element: <Administration /> },
  { path: "/job-list", element: <JobList /> },
  { path: "/job-results", element: <JobResults /> },
  { path: "/job-results/:id", element: <JobResults /> },
  { path: "/file-upload", element: <Upload /> },
  { path: "/reports", element: <Reports /> },
  { path: "/", element: <Navigate to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/logout", element: <Logout /> },
]

export { authProtectedRoutes, publicRoutes }
