import React, { useState } from "react"
import BasicCalendar from "components/BasicCalendar"
import { Alert, Button } from "@mui/material"
import { error } from "toastr"
import { ErrorMessage } from "formik"

const ReportsTemplate = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleDownloadReport,
  errorMessage,
  handleclickRadio,
  radioValue,
}) => {
  return (
    <div className="page-content">
      <div className="container">
        <h4 className="mb-4">Reporte de Gestión Analistas</h4>
        <div className="row py-3">
          <div className="col-6">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                defaultChecked
                onClick={() => handleclickRadio("detalle")}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                Detalle
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                onClick={() => handleclickRadio("resumen")}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                Resumen
              </label>
            </div>
          </div>
        </div>
        <div className="row align-items-end">
          <div className="col-3">
            <BasicCalendar
              label="Fecha Inicio"
              value={startDate}
              setCalendarValue={startDate => setStartDate(startDate)}
            />
          </div>
          <div className="col-3">
            <BasicCalendar
              label="Fecha Fin"
              value={endDate}
              setCalendarValue={endDate => setEndDate(endDate)}
            />
          </div>
          <div className="col-3">
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadReport}
              fullWidth
            >
              Descargar Reporte
            </Button>
          </div>
        </div>
        {errorMessage && (
          <div className="col-6">
            <Alert
              sx={{
                marginTop: "20px",
              }}
              severity="info"
            >
              {errorMessage}
            </Alert>
          </div>
        )}
      </div>
    </div>
  )
}

export default ReportsTemplate
