import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ReportsTemplate from "ui/templates/Reports"
import { fetchLogsByDate } from "store/logger/actions"

// Utilizamos la librería `Date` para formatear las fechas y horas
const formatDate = dateString => {
  const date = new Date(dateString)
  return date.toISOString().split("T")[0] // Solo la fecha en formato "YYYY-MM-DD"
}

const formatTime = timeString => {
  const timeParts = timeString.split(".")[0] // Eliminamos los microsegundos
  return timeParts // Deja el formato "HH:mm:ss"
}

const ReportsContainer = () => {
  const dispatch = useDispatch()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [errorMessage, setErrorMessage] = useState("")
  const [radioValue, setRadioValue] = useState("detalle")
  console.log("🚀 ~ ReportsContainer ~ errorMessage:", errorMessage)

  // Actualizado con la estructura correcta para obtener los datos
  const { data: report } = useSelector(state => state?.Logger?.report || {})

  const handleDownloadReport = () => {
    if (startDate) {
      dispatch(fetchLogsByDate({ startDate, endDate, type: radioValue }))
      console.log(
        "Generando reporte desde:",
        startDate,
        // "hasta:",
        endDate || "sin fecha final"
      )
    } else {
      setErrorMessage("Por favor seleccione una fecha de inicio.")
    }
  }

  const handleclickRadio = value => {
    console.log("🚀 ~ handleclickRadio ~ value:", value)
    setRadioValue(value)
  }

  // Convertir los datos de report a un formato CSV
  const downloadCSV = (data, filename) => {
    if (data && data.length > 0) {
      const headers = Object.keys(data[0])
      const csvRows = [
        headers.join(";"), // La primera fila es el encabezado
        ...data.map(row =>
          headers
            .map(header => {
              if (header === "fecha") {
                // Si la columna es fecha, la formateamos
                return formatDate(row[header]) || ""
              } else if (header === "hora") {
                // Si la columna es hora, la formateamos
                return formatTime(row[header]) || ""
              }
              return row[header] || "" // Para el resto de las columnas
            })
            .join(";")
        ),
      ]

      const csv = csvRows.join("\n")
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.style.display = "none"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      setErrorMessage(
        "No se encontraron datos para descargar en las fechas seleccionadas."
      )
    }
  }

  // Función que se ejecuta cuando los logs están disponibles
  const handleDownloadCSV = () => {
    if (report?.length > 0) {
      downloadCSV(report, "reporte.csv")
    } else {
      console.log(
        "🚀 ~ handleDownloadCSV ~ No se encontraron datos para descargar.:"
      )
    }
  }

  // Descargamos el reporte tan pronto como los logs estén disponibles
  useEffect(() => {
    if (report && report.length > 0) {
      handleDownloadCSV() // Descargar el CSV cuando los datos sean cargados
    } else {
      if (report?.length === 0)
        setErrorMessage("No se encontraron datos para descargar.")
    }
  }, [report]) // Solo se ejecuta cuando `report` cambia

  return (
    <ReportsTemplate
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      handleDownloadReport={handleDownloadReport}
      errorMessage={errorMessage}
      handleclickRadio={handleclickRadio}
      radioValue={radioValue}
    />
  )
}

export default ReportsContainer
