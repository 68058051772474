import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchLogs } from "../../../store/actions" // Asegúrate de que esta acción esté correctamente definida

// Función para obtener el sistema operativo
const getSistemaOperativo = () => {
  const userAgent = navigator.userAgent
  if (userAgent.indexOf("Win") !== -1) return "Windows"
  if (userAgent.indexOf("Mac") !== -1) return "MacOS"
  if (userAgent.indexOf("X11") !== -1) return "Unix"
  if (userAgent.indexOf("Linux") !== -1) return "Linux"
  return "No disponible"
}

const Logger = ({ job_id, rut, accion, informacion_adicional = "" }) => {
  console.log("🚀 ~ Logger ~ accion:", accion)
  const dispatch = useDispatch()

  // const { job_id } = useSelector(state => state?.JobById?.data)
  const { userName } = useSelector(state => state?.AuthUser)
  // const { rut_cliente } = useSelector(state => state?.FileInfo?.data)

  // Variables para el log
  const sistemaOperativo = getSistemaOperativo() || "No disponible"
  const navegador = navigator.userAgent || "No disponible"
  const idioma = navigator.language || "No disponible"
  const resolucionPantalla =
    `${window.screen.width}x${window.screen.height}` || "No disponible"
  const estadoRed = !navigator.onLine ? "Offline" : "Online"
  const mensajeError = null // Puedes cambiar este valor si lo necesitas

  // El logData que contiene todos los valores
  const logData = {
    job_id, //prop
    rut, //prop
    username: userName, // Obtenido de la store
    accion,
    estado: "SUCCESS", // Valor por defecto
    codigo_respuesta: "200", // Valor por defecto
    informacion_adicional, // Este campo puede estar vacío
    sistema_operativo: sistemaOperativo,
    navegador: navegador,
    idioma: idioma,
    resolucion_pantalla: resolucionPantalla,
    estado_red: estadoRed,
    mensaje_error: mensajeError,
  }

  // useEffect para disparar la acción solo cuando cambien las dependencias
  useEffect(() => {
    if (accion && job_id) {
      dispatch(fetchLogs(logData)) // Despachar la acción solo si los datos son válidos
    }
  }, [dispatch, accion]) // Dependencias de useEffect

  return null // Este componente no tiene UI, solo se encarga del logging
}

export default Logger
