import {
  FETCH_FILES_INFO,
  FETCH_FILES_INFO_SUCCESS,
  FETCH_FILES_INFO_ERROR,
  FETCH_FILES_INFO_RESET,
  SEARCH_FILES_INFO,
  SEARCH_FILES_INFO_SUCCESS,
  SEARCH_FILES_INFO_ERROR,
} from "./actionTypes"

export const fetchFilesInfo = id => ({
  type: FETCH_FILES_INFO,
  payload: id,
})

export const fetchFilesInfoSuccess = data => ({
  type: FETCH_FILES_INFO_SUCCESS,
  payload: data,
})

export const fetchFilesInfoError = error => ({
  type: FETCH_FILES_INFO_ERROR,
  payload: error,
})

export const fetchFilesInfoReset = () => ({
  type: FETCH_FILES_INFO_RESET,
})

export const searchFilesInfo = query => ({
  type: SEARCH_FILES_INFO,
  payload: query,
})

export const searchFilesInfoSuccess = data => ({
  type: SEARCH_FILES_INFO_SUCCESS,
  payload: data,
})

export const searchFilesInfoError = error => ({
  type: SEARCH_FILES_INFO_ERROR,
  payload: error,
})
